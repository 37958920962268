<template>
  <n-card title="App notifications">
    <n-spin :show="loading">
      <n-timeline>
        <n-timeline-item
          v-for="(notification, key) in notifications"
          :type="notification.data.type"
          :title="notification.data.action"
          :time="$filters.calendar(notification.created_at)"
          :key="key"
        >
          <span v-html="notification.data.description"/>
        </n-timeline-item>
      </n-timeline>
      <infinite-loading @infinite="getNotifications">
        <template #spinner>&nbsp;</template>
        <template #complete>&nbsp;</template>
      </infinite-loading>
    </n-spin>
  </n-card>
</template>
<script setup>
import InfiniteLoading from 'v3-infinite-loading';
import { ref, inject } from 'vue';

const axios = inject('axios');

const notifications = ref([]);
const loading = ref(true);
let page = 1;

const getNotifications = async ($state) => {
  try {
    loading.value = true;
    const response = await axios.get(`notifications?page=${page}`);
    $state.loaded();
    if (response.data.meta.last_page < page) $state.complete();
    else {
      notifications.value.push(...response.data.data);
      $state.loaded();
    }
    page += 1;
  } catch (error) {
    $state.error();
  } finally {
    loading.value = false;
  }
};
</script>
