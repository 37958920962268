<template>
  <n-card title="Installing application" style="height: 100%">
    <n-space vertical class="info" v-if="result">
      <app-icon :type="result.icon" size="100" :color="result.iconColor" />
      <h3>{{ result.message }}</h3>
      <h4>{{ result.extra }}</h4>
      <h4>{{ result.extra2 }}</h4>
    </n-space>
    <n-space vertical class="info" v-if="status === null">
      <n-spin size="large" />
      <h3>It will take a while..</h3>
    </n-space>
    <template #action>
      <n-button type="success" v-if="status" @click="router.push('/warehouse')"
      >View products</n-button
      >
      <n-button v-if="status === false" @click="router.push('/stores')">Ok</n-button>
    </template>
  </n-card>
</template>
<script setup>
import {
  onMounted, ref, inject, computed,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

const axios = inject('axios');
const emit = defineEmits(['status']);
const route = useRoute();
const router = useRouter();
const status = ref(null);

const message = {
  true: {
    icon: 'CheckCircleRegular',
    iconColor: '#19A058',
    message: 'You’re all set!',
    extra: 'If you connected an existing Merchr Hub account, you can head back there now to start creating products.',
    extra2: 'For new users, your Merchr Hub login details have been sent to the email address linked to your Shopify account. Please log in to the Hub to complete the setup process.',
  },
  false: {
    icon: 'TimesCircleRegular',
    iconColor: '#D03050',
    message: 'The installation failed.',
    extra: 'Please check your settings or contact our support team.',
    extra2: '',
  },
};

const result = computed({
  get: () => message[status.value] ?? null,
});

const oAuth = async () => {
  await axios.post('stores', {
    store: route.query.shop,
    code: route.query.code,
    marketplace: 'shopify',
    store_name: localStorage.getItem('store_name'),
  });
};

onMounted(async () => {
  if (route.query?.code === undefined) {
    status.value = false;
    emit('status', false);
    return;
  }
  try {
    await oAuth();
    status.value = true;
    localStorage.removeItem('shop');
    localStorage.removeItem('store_name');
  } catch (err) {
    status.value = false;
    message.false.extra = err.response.data.message;
  }
});
</script>
<style scoped>
.info {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
