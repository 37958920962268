<template>
  <n-form size="large" :rules="rules" :model="formValue" ref="formRef" @keydown.enter="auth">
    <n-form-item-row label="E-mail" path="email">
      <n-input v-model:value="formValue.email" placeholder="Input your email"/>
    </n-form-item-row>
    <n-form-item-row label="Password" path="password">
      <n-input
        v-model:value="formValue.password"
        type="password"
        show-password-on="click"
        placeholder="Input your password"
      >
        <template #password-visible-icon>
          <app-icon type="EyeSlashRegular"/>
        </template>
        <template #password-invisible-icon>
          <app-icon type="EyeRegular"/>
        </template>
      </n-input>
    </n-form-item-row>
    <n-form-item-row>
      <n-button :loading="loading" type="primary" size="large" block @click="auth">
        Sign in
      </n-button>
    </n-form-item-row>
  </n-form>
  <n-space justify="center">
    Don't have an account?
    <router-link to="/registration" #="{ navigate, href }">
      <n-a :href="href" @click="navigate"><strong>Create One</strong></n-a>
    </router-link>
    <router-link to="/forgot-password" #="{ navigate, href }">
      <n-a :href="href" @click="navigate"> Forgot password?</n-a>
    </router-link>
  </n-space>
</template>
<script setup>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import state from '@/state';

const router = useRouter();
const handleErrors = inject('handleErrors');
const axios = inject('axios');
const formRef = ref(null);
const formValue = ref({
  email: '',
  password: '',
});
const rules = {
  email: {
    type: 'email',
    required: true,
    message: 'Please input your e-mail',
    trigger: ['input', 'blur'],
  },
  password: {
    required: true,
    message: 'Please input your password',
    trigger: ['input', 'blur'],
  },
};
const loading = ref(false);

const auth = async (e) => {
  e.preventDefault();
  try {
    loading.value = true;
    await formRef.value?.validate();
    const response = await axios.post('auth', formValue.value);
    state.setToken(response.data.data.token);
    localStorage.setItem('id', response.data.data.id);
    await router.push('/');
  } catch (error) {
    handleErrors(error);
  } finally {
    loading.value = false;
  }
};
</script>
<style scoped></style>
