<template>
  <n-form size="large" :rules="rules" :model="formValue" ref="formRef"
          @keydown.enter="createAccount">
    <n-form-item-row label="E-mail" path="email">
      <n-input v-model:value="formValue.email" placeholder="Input your email"/>
    </n-form-item-row>
    <n-form-item-row label="Password" path="password">
      <n-input
        v-model:value="formValue.password"
        type="password"
        show-password-on="click"
        placeholder="Input your password"
      >
        <template #password-visible-icon>
          <app-icon type="EyeSlashRegular"/>
        </template>
        <template #password-invisible-icon>
          <app-icon type="EyeRegular"/>
        </template>
      </n-input>
    </n-form-item-row>
    <n-form-item-row label="Confirm Password" path="password_confirmation">
      <n-input
        v-model:value="formValue.password_confirmation"
        type="password"
        show-password-on="click"
        placeholder="Input your password"
      >
        <template #password-visible-icon>
          <app-icon :type="'EyeSlashRegular'"/>
        </template>
        <template #password-invisible-icon>
          <app-icon :type="'EyeRegular'"/>
        </template>
      </n-input>
    </n-form-item-row>
    <n-form-item-row>
      <n-button :loading="loading" type="primary" size="large" block @click="createAccount">
        Create account
      </n-button>
    </n-form-item-row>
  </n-form>
  <n-space justify="center">
    Already have an account?
    <router-link to="/login" #="{ navigate, href }">
      <n-a :href="href" @click="navigate">Login</n-a>
    </router-link>
  </n-space>
</template>
<script setup>
import { ref, inject } from 'vue';
import { useMessage } from 'naive-ui';
import { useRouter } from 'vue-router';

const message = useMessage();
const router = useRouter();
const handleErrors = inject('handleErrors');
const axios = inject('axios');
const formRef = ref(null);
const formValue = ref({
  email: '',
  password: '',
  password_confirmation: '',
});
const validatePasswordConfirmation = (rule, value) => formRef.value.model.password === value;
const rules = {
  email: {
    type: 'email',
    required: true,
    message: 'Please input your e-mail',
    trigger: ['input', 'blur'],
  },
  password: [
    {
      required: true,
      message: 'Please input your password',
      trigger: ['input', 'blur'],
    },
    {
      type: 'string',
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
      message:
        'Password must contain min 8 characters, 1 number, 1 special character, 1 lowercase letter, and 1 uppercase letter.',
      trigger: ['input'],
    },
  ],
  password_confirmation: [
    {
      required: true,
      message: 'Please repeat your password',
      trigger: ['input', 'blur'],
    },
    {
      validator: validatePasswordConfirmation,
      message: 'Password is not same as re-entered password!',
      trigger: ['blur', 'password_confirmation'],
    },
  ],
};

const loading = ref(false);
const createAccount = async (e) => {
  e.preventDefault();
  try {
    loading.value = true;
    await formRef.value?.validate();
    await axios.post('users', formValue.value);
    message.success('You have been successfully registered.');
    router.push('/login');
  } catch (err) {
    handleErrors(err);
  } finally {
    loading.value = false;
  }
};
</script>
