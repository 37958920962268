<template>
  <n-card title="Shopify connection" style="height:100%;">
    <n-form ref="formRef" :model="formValue" :rules="rules">
      <n-form-item label="Store Name" path="store">
        <n-auto-complete size="large" v-model:value="formValue.store_name" :options="options"
                         placeholder="Enter an easy recognizable name for your shop">
          <template #password-invisible-icon>
            <app-icon type="EyeRegular" />
          </template>
        </n-auto-complete>
      </n-form-item>
    </n-form>
    <template #action>
      <n-button :loading="loading" @click="handle">Next</n-button>
    </template>
  </n-card>
</template>
<script setup>
import handleErrors from '@/composables/error';
import {
  ref, inject, reactive, onMounted, computed,
} from 'vue';

const emit = defineEmits(['step:status']);
const axios = inject('axios');
const formRef = ref(null);
const loading = ref(false);
const merchrStores = ref([]);
const stores = ref([]);
const formValue = reactive({
  store_name: '',
  store: '',
});
const rules = {
  store_name: [
    {
      type: 'string',
      required: true,
      trigger: ['input'],
      message: 'Enter Store name',
    },
    {
      validator(_, value) {
        const store = stores.value.find((item) => item.store === value);
        return store === undefined;
      },
      message: 'The store is already connected.',
    },
  ],
};

const handle = async () => {
  try {
    loading.value = true;
    await formRef.value?.validate();
    formValue.store = localStorage.getItem('shop');
    const response = await axios.post('shopify-install', formValue);
    localStorage.setItem('store_name', formValue.store_name);
    window.location.replace(response.data.data.redirect_url);
    // emit('step:status', true);
  } catch (err) {
    emit('step:status', false);
  } finally {
    loading.value = false;
  }
};

const options = computed({
  get: () => merchrStores.value.map((item) => {
    // eslint-disable-next-line no-unused-vars
    const { hostname } = new URL(item.store_url);
    return {
      label: item.store_name,
      value: item.store_name,
    };
  }),
});

const getStores = async () => {
  try {
    const response = await axios.get('stores');
    stores.value = response.data.data;
  } catch (err) {
    handleErrors(err);
  }
};

const getMerchrStores = async () => {
  try {
    const response = await axios.get('merchr-stores');
    merchrStores.value = response.data.data;
  } catch (err) {
    handleErrors(err);
  }
};

onMounted(() => {
  getMerchrStores();
  getStores();
});
</script>
