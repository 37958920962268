<template>
  <n-card title="My Stores">
    <template #header-extra>
      <n-button v-show="!data[0]" type="primary" @click="$router.push('stores/connection')">
        Add new store
      </n-button>
    </template>
    <n-data-table
      ref="table"
      remote
      :loading="loading"
      :data="data"
      :columns="columns"
      :pagination="false"
      :bordered="false"
      size="large"
    />
  </n-card>
</template>
<script setup>
import {
  inject, h, ref, onMounted,
} from 'vue';
import {
  NButton, NIcon, NTooltip, useDialog,
} from 'naive-ui';
import { TrashAltRegular } from '@vicons/fa';
import handleErrors from '@/composables/error';

const dialog = useDialog();
const axios = inject('axios');
const filters = inject('filters');

const data = ref([]);
const loading = ref(true);
const table = ref({});

const getStores = async () => {
  try {
    const response = await axios.get('stores');
    data.value = response.data.data;
  } catch (err) {
    handleErrors(err);
  } finally {
    loading.value = false;
  }
};

// eslint-disable-next-line consistent-return
const deleteStore = async (id) => {
  try {
    const response = await axios.delete(`stores/${id}`);
    localStorage.removeItem('shop');
    return response.data.data;
  } catch (err) {
    handleErrors(err);
  }
};

onMounted(async () => {
  await getStores();
});

// DataTable settings
const columns = [
  {
    title: 'Store',
    key: 'store',
  },
  {
    title: 'Marketplace',
    key: 'marketplace',
  },
  {
    title: 'Status',
    key: 'status_label',
  },
  {
    title: 'Connected At',
    key: 'created_at',
    render(store) {
      return filters.calendar(store.created_at);
    },
  },
  {
    title: '',
    key: 'actions',
    align: 'right',
    render(store) {
      return h(
        NTooltip,
        { trigger: 'hover' },
        {
          trigger: () => h(
            NButton,
            {
              onClick: () => dialog.error({
                title: 'Delete store?',
                content: 'Are you sure?',
                positiveText: 'Yes',
                negativeText: 'No',
                onPositiveClick: async () => {
                  await deleteStore(store.id);
                  await getStores();
                },
              }),
            },
            { default: () => h(NIcon, { component: TrashAltRegular }) },
          ),
          default: () => 'Delete',
        },
      );
    },
  },
];
</script>
