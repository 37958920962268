<template>
  <n-result status="success" title="Check your email" size="small" v-if="sent">
    An email has been sent to {{ formValue.email }} with instructions to reset your password.
    If the email doesn’t show up soon, check your spam folder or make sure you enter the email
    you used for registration.
    <template #footer>
      <n-button @click="goBack">Back to Login</n-button>
    </template>
  </n-result>
  <n-form size="large" :rules="rules" :model="formValue" ref="formRef" v-else
          @keydown.enter="sendEmail">
    <p>
      Enter your email below, you will receive an email with instructions on how to reset
      your password in a few minutes.
    </p>
    <n-form-item-row label="E-mail" path="email">
      <n-input v-model:value="formValue.email" placeholder="Input your email"/>
    </n-form-item-row>
    <n-space vertical style="width: 100%">
      <n-button :loading="loading" type="primary" size="large" block @click="sendEmail">
        Send E-mail
      </n-button>
      <n-button size="large" block @click="goBack">
        Go back
      </n-button>
    </n-space>
  </n-form>
</template>
<script setup>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const handleErrors = inject('handleErrors');
const axios = inject('axios');
const formRef = ref(null);
const formValue = ref({
  email: '',
  password: '',
});
const rules = {
  email: {
    type: 'email',
    required: true,
    message: 'Please input your e-mail',
    trigger: ['input', 'blur'],
  },
};
const loading = ref(false);
const sent = ref(false);

const goBack = () => router.push('/login');

const sendEmail = async (e) => {
  e.preventDefault();
  try {
    loading.value = true;
    await formRef.value?.validate();
    await axios.post('forgot-passwords', formValue.value);
    sent.value = true;
  } catch (error) {
    handleErrors(error);
  } finally {
    loading.value = false;
  }
};
</script>
