<template>
  <n-space vertical id="products-container">
    <n-collapse-transition :show="filter">
      <n-card embedded size="small">
        <n-form inline>
          <n-form-item label="Search">
            <n-input
              v-model:value="queryParams.keywords"
              placeholder="Order number or ID"
              clearable
            />
          </n-form-item>
          <n-form-item style="width: 200px" label="Store">
            <n-select
              filterable
              remote
              clearable
              placeholder="Select store"
              label-field="store"
              value-field="id"
              :options="stores"
              v-model:value="queryParams.store_id"
            />
          </n-form-item>
          <n-form-item style="width: 200px" label="Payment status">
            <n-select
              filterable
              remote
              clearable
              placeholder="Select payment status"
              label-field="label"
              value-field="value"
              :options="paymentStatuses"
              v-model:value="queryParams.payment_status"
            />
          </n-form-item>
        </n-form>
      </n-card>
    </n-collapse-transition>
    <n-card title="Order fulfillments">
      <template #header-extra>
        <n-switch v-model:value="filter" :round="false">
          <template #checked-icon>
            <app-icon type="FilterRemove"/>
          </template>
          <template #unchecked-icon>
            <app-icon type="Filter"/>
          </template>
        </n-switch>
      </template>
      <n-space vertical>
        <n-data-table
          ref="table"
          remote
          :loading="loading"
          :data="data"
          :columns="columns"
          :bordered="false"
        />
        <n-pagination v-model:page="queryParams.page" :page-count="pagination.total"/>
      </n-space>
    </n-card>
  </n-space>
  <n-drawer v-model:show="drawer" :width="502">
    <n-drawer-content title="Line items" closable>
      <n-space v-for="(product, key) in drawerOrder.products" :key="key" vertical>
        <n-thing content-indented>
          <template #avatar>
            <n-avatar :src="product.sync.variant.image.medium_abs_url"/>
          </template>
          <template #header>
            {{ product.sync.variant.title }}
          </template>
          <template #header-extra>
            <n-tag size="small">
              {{ product.sync.variant.sku }}
            </n-tag>
          </template>
          <template #description>
            <n-space vertical>
              <span>Quantity: {{ product.quantity }}</span>
              <span>Selling price: {{ $filters.money(product.sync.variant.selling_price
                  * product.quantity) }}
              </span>
              <span>Product cost: {{ $filters.money((parseFloat(product.sync.variant.cost)
                  + parseFloat(product.sync.variant.processing_fee)) * 1.2 * product.quantity) }}
              </span>
              <span>{{ product.print_params.length === 0 ? '' : product.print_params  }}</span>
            </n-space>
          </template>
        </n-thing>
      </n-space>
    </n-drawer-content>
  </n-drawer>
</template>
<script setup>
import {
  h, inject, onMounted, reactive, ref, watch,
} from 'vue';
import {
  NButton, NTooltip, NA, useDialog, useMessage, NBadge,
} from 'naive-ui';
import handleErrors from '@/composables/error';
import AppIcon from '@/components/AppIcon.vue';
// import { useRouter } from 'vue-router';

// const router = useRouter();
// eslint-disable-next-line no-unused-vars
const dialog = useDialog();
const axios = inject('axios');
// eslint-disable-next-line no-unused-vars
const filters = inject('filters');
// eslint-disable-next-line no-unused-vars
const message = useMessage();

const filter = ref(true);
const data = ref([]);
const loading = ref(true);
const table = ref({});
const stores = ref([]);
const drawer = ref(false);
const drawerOrder = ref({});
const paymentStatuses = ref([]);

const pagination = reactive({
  total: 0,
});

const queryParams = reactive({
  keywords: '',
  page: 1,
  store_id: null,
  payment_status: null,
});

const getOrders = async () => {
  try {
    loading.value = true;
    const response = await axios.get('order-fulfillments', { params: queryParams });
    data.value = response.data.data;
    queryParams.page = response.data.meta.current_page;
    pagination.total = response.data.meta.last_page;
  } catch (err) {
    handleErrors(err);
  } finally {
    loading.value = false;
  }
};

const getPaymentStatuses = async () => {
  try {
    const response = await axios.get('payment-statuses');
    paymentStatuses.value = response.data.data;
  } catch (err) {
    handleErrors(err);
  }
};

const getStores = async () => {
  try {
    const response = await axios.get('stores');
    stores.value = response.data.data;
  } catch (err) {
    handleErrors(err);
  }
};

const openLineItems = (order) => {
  drawerOrder.value = order;
  drawer.value = drawer.value !== true;
};

watch(
  () => queryParams,
  () => getOrders(),
  { deep: true },
);

onMounted(async () => {
  await getOrders();
  await getStores();
  await getPaymentStatuses();
});

// DataTable settings
const columns = [
  {
    title: 'Order #',
    key: 'marketplace_order_number',
    render(order) {
      return h(NA, {
        href: order.marketplace_admin_order_url,
        target: '_blank',
      }, () => `# ${order.marketplace_order_number} - (${order.marketplace_order_id})`);
    },
  },
  {
    title: 'Approval Status',
    key: 'payment_status_label.value',
    render(order) {
      return h(NBadge, {
        value: order.payment_status_label.value,
        type: order.payment_status_label.type,
      });
    },
  },
  {
    title: 'Order Status',
    key: 'marketplace_status',
    render(order) {
      return order.marketplace_status.charAt(0).toUpperCase() + order.marketplace_status.slice(1);
    },
  },
  {
    title: 'Request status',
    key: 'marketplace_request_status',
    render(order) {
      return order.marketplace_request_status.charAt(0).toUpperCase()
          + order.marketplace_request_status.slice(1);
    },
  },
  {
    title: 'Store',
    key: 'store.store',
  },
  {
    title: 'Product Cost (+ Processing Fee) inc. Tax',
    key: 'product_cost_sum',
    render(order) {
      return filters.money(order.product_cost_sum);
    },
  },
  {
    title: 'Sale Price',
    key: 'sale_price_sum',
    render(order) {
      return filters.money(order.sale_price_sum);
    },
  },
  {
    title: 'Profit',
    key: 'revenue',
    render(order) {
      return filters.money(order.revenue);
    },
  },
  {
    title: 'Created At',
    key: 'created_at',
    render(order) {
      return filters.calendar(order.created_at);
    },
  },
  {
    title: '',
    key: 'line_items_quantity',
    align: 'left',
    render(order) {
      return h(
        NTooltip,
        { trigger: 'hover' },
        {
          trigger: () => h(
            NBadge,
            {
              value: order.line_items_quantity,
              // eslint-disable-next-line no-return-assign
              onClick: () => openLineItems(order),
            },
            {
              default: () => h(NButton, { size: 'small' }, () => h(AppIcon, { type: 'ShoppingCart' })),
            },
          ),
          default: () => 'Preview order items',
        },
      );
    },
  },
  // {
  //   title: '',
  //   key: 'pay',
  //   width: '50',
  //   render(order) {
  //     if (order.payment_status !== 1) {
  //       return h(
  //         NTooltip,
  //         { trigger: 'hover' },
  //         {
  //           trigger: () => h(
  //             NButton,
  //             {
  //               size: 'small',
  //               type: 'success',
  //               onClick: () => router.push(`/orders/payment/${order.id}`),
  //             },
  //             { default: () => h(AppIcon, { type: 'CreditCardRegular' }) },
  //           ),
  //           default: () => 'Payment',
  //         },
  //       );
  //     }
  //     return null;
  //   },
  // },
];
</script>
