import { reactive } from 'vue';

const data = reactive({
  token: null,
});

const state = {
  setToken: (token) => {
    data.token = token;
    localStorage.setItem('token', token);
  },
  getToken: () => {
    data.token = localStorage.getItem('token') ?? null;
    return data.token;
  },
};

export default state;
