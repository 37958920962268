<template>
  <n-layout-header bordered>
    <n-space :size="20" align="center" style="line-height: 1">
      <n-popover trigger="click" placement="bottom-end" :width="300" v-if="notifications.length">
        <template #trigger>
          <n-badge @click="markAsRead">
            <app-icon :type="'BellRegular'" size="18" :depth="2"/>
          </n-badge>
        </template>
        <n-list style="margin: 0">
          <n-list-item v-for="i in 6" :key="i">
            <span v-html="notifications[i].data.description"/>
          </n-list-item>
        </n-list>
      </n-popover>
      <n-dropdown placement="bottom-end" show-arrow :options="options" @select="handleSelect">
        <app-icon :type="'User'" size="18" :depth="2"/>
      </n-dropdown>
    </n-space>
  </n-layout-header>
</template>
<script setup>
import {
  inject, ref, onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import handleErrors from '@/composables/error';
import state from '@/state';

const router = useRouter();
const axios = inject('axios');
const notifications = ref([]);
const options = [
  { key: 'profile', label: 'Profile' },
  { key: 'divider', type: 'divider' },
  { key: 'logout', label: 'Sign out' },
];

const handleSelect = async (value) => {
  // eslint-disable-next-line default-case
  switch (value) {
    case 'logout':
      try {
        await axios.post('logout');
        state.setToken(null);
        await router.push('/login');
      } catch (e) {
        handleErrors(e);
      }
      break;
    case 'profile':
      await router.push('/profile');
      break;
  }
};

const getNotifications = async () => {
  if (state.getToken() !== null && state.getToken() !== 'null') {
    try {
      const response = await axios.get('notifications');
      notifications.value = response.data.data;
    } catch (e) {
      handleErrors(e);
    }
  }
};

const markAsRead = () => null;

onMounted(async () => {
  if (state.getToken()) {
    await getNotifications();
  }
});

</script>
<style scoped>
.n-layout-header {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 9px 18px;
}

.n-button {
  margin-right: 15px;
}

.n-space {
  margin-left: auto;
}
</style>
