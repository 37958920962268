<template>
  <n-card :title="title">
    <n-skeleton text :repeat="6" v-if="loading" />
    <n-checkbox-group v-model:value="options">
      <n-grid :y-gap="8" :cols="1">
        <n-gi v-for="option in values" :key="option">
          <n-checkbox :value="option.value"> {{ option.value }} ({{ option.count }}) </n-checkbox>
        </n-gi>
      </n-grid>
    </n-checkbox-group>
  </n-card>
</template>
<script setup>
import { computed } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  values: {
    type: Array,
    require: true,
  },
  loading: {
    type: Boolean,
  },
});

const options = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});
</script>
