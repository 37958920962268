<template>
  <n-card title="Home">
    <n-grid cols="24" item-responsive responsive="screen" x-gap="12" y-gap="12">
      <n-grid-item span="24 s:12 m:12 l:12" x-gap="12" y-gap="12">
        <img src="~@/assets/illustrations/welcome.svg" alt="logo" style="width: 370px" />
      </n-grid-item>
      <n-grid-item span="24 s:12 m:12 l:12" x-gap="12" y-gap="12">
        <n-list>
          <n-list-item>
            <n-thing title="Connect your Shopify store">
              Visit <router-link to="/stores">My Stores</router-link>
              to quickly link your Shopify store with the Merchr Hub.
               If you haven’t got a Hub account, we’ll create one here too
            </n-thing>
          </n-list-item>
          <n-list-item>
            <n-thing title="Visit the Hub to design your products">
              Select products from our huge on-demand library and customise
              them using our online designer tool. Choose from our curated
              designs or upload your own design, artwork or logo
            </n-thing>
          </n-list-item>
          <n-list-item>
            <n-thing title="Add a card to cover your production costs">
              Store a card or top up your account balance so your customer’s
              orders go seamlessly from purchase to production!
            </n-thing>
          </n-list-item>
        </n-list>
      </n-grid-item>
    </n-grid>
    <template #action>
      <n-button type="success" @click="router.push('/stores')">My Stores</n-button>
      <a href="https://hub.merchr.com" target="_blank" class="merchr-btn-left-margin">
        <n-button type="primary" >Merchr Hub</n-button>
      </a>
    </template>
  </n-card>
</template>
<script setup>
import { inject } from 'vue';
import { useRouter } from 'vue-router';
import state from '@/state';

const router = useRouter();
const axios = inject('axios');

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const isInstall = urlParams.get('is_install');

if ((state.getToken() === null || state.getToken() === 'null') && isInstall === null) {
  router.push('/login');
}

if (localStorage.getItem('shop')) {
  const response = await axios.get('stores');
  response.data.data.forEach((store) => {
    if (store.store === localStorage.getItem('shop')) {
      localStorage.removeItem('shop');
    }
  });
}

try {
  axios.get('health-check');
} catch (err) {
  console.warn(err);
}
</script>
<style>
.merchr-btn-left-margin {
  margin-left:10px;
}
</style>
