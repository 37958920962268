<template>
  <suspense v-if="isInstall">
    <loading :active="true"
     :can-cancel="true"
     :is-full-page="true">
    </loading>
  </suspense>
  <suspense v-else>
    <n-layout position="absolute" has-sider>
      <app-sidebar/>
      <n-layout :native-scrollbar="false">
        <app-header/>
        <n-layout-content content-style="padding: 24px; min-height: 91vh">
          <router-viewer/>
        </n-layout-content>
        <app-footer/>
      </n-layout>
    </n-layout>
  </suspense>
</template>
<script setup>
import RouterViewer from '@/components/RouterViewer.vue';
import AppSidebar from '@/layouts/AppSidebar.vue';
import AppFooter from '@/layouts/AppFooter.vue';
import AppHeader from '@/layouts/AppHeader.vue';
import { h } from 'vue';
import { useMessage, useNotification } from 'naive-ui';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import state from '@/state';
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

window.$message = useMessage();
const notification = useNotification();
Pusher.logToConsole = false;

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const hmac = urlParams.get('hmac');
const host = urlParams.get('host');
const code = urlParams.get('code');
const embedded = urlParams.get('embedded');
const isInstallParam = urlParams.get('is_install');
let isInstall;

if (isInstallParam !== null) {
  isInstall = true;
} else {
  isInstall = hmac !== null && host !== null && code === null && embedded === null;
}

if (state.getToken()) {
  const echo = new Echo({
    authEndpoint: `${process.env.VUE_APP_BACKEND_URL}api/broadcasting/auth`,
    broadcaster: process.env.VUE_APP_BROADCASTER,
    key: process.env.VUE_APP_PUSHER_KEY,
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    encrypted: true,
    auth: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  });
  echo.private(`App.Models.User.${localStorage.getItem('id')}`).notification((data) => {
    notification.info({ content: () => h('span', { innerHTML: data.description }) });
  });
}

</script>

<style scoped>
.n-layout {
  background-color: #f4f7fd;
}
</style>
