<template>
  <n-grid item-responsive responsive="screen" x-gap="12" y-gap="12">
    <n-grid-item span="24 s:11 m:7">
      <n-card style="height: 100%;">
        <n-steps vertical :current="current" :status="currentStatus">
          <n-step title="Merchr access" description="When you already have Merchr account" />
          <n-step title="Store settings" description="Shopify connection" />
          <n-step title="Finish" description="Summary Information" />
        </n-steps>
      </n-card>
    </n-grid-item>
    <n-grid-item span="24 s:13 m:17">
      <component :is="step" @step:status="handleStatus" />
    </n-grid-item>
  </n-grid>
</template>
<script setup>
import { computed, onBeforeMount, ref } from 'vue';
import { useRoute } from 'vue-router';
import MerchrConnection from '@/views/stores/steps/MerchrConnection.vue';
import MarketplaceConnection from '@/views/stores/steps/MarketplaceConnection.vue';
import SummaryInformation from '@/views/stores/steps/SummaryInformation.vue';

const route = useRoute();
const current = ref(1);
const currentStatus = ref('process');

const handleStatus = (status) => {
  if (status === true) {
    currentStatus.value = 'success';
    current.value += 1;
  } else {
    currentStatus.value = 'error';
  }
};

const step = computed({
  get: () => {
    switch (current.value) {
      case 1:
        return MerchrConnection;
      case 2:
        return MarketplaceConnection;
      case 3:
        return SummaryInformation;
      default:
        return MerchrConnection;
    }
  },
});

onBeforeMount(() => {
  if (route.query?.code) {
    current.value = 3;
  }
});

</script>
