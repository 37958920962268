<template>
  <n-space vertical>
    <n-card title="Products">
      <n-grid cols="24" item-responsive responsive="screen" x-gap="12" y-gap="12">
        <n-grid-item span="24 s:12 m:12 l:12" x-gap="12" y-gap="12">
          <h3>Design your own</h3>
          <p>
            Are you looking to design your own products? Do you want to allow your customers to
            personalise your products?
          </p>
          <p>
            Then login to the Merchr Hub using the button below, when you first connected your
            store, an email will have been sent containing your login credentials.
          </p>
          <p>
            Once you have published your first store product, they will automatically be added
            to your Shopify store and show below.
          </p>
        </n-grid-item>
        <n-grid-item span="24 s:12 m:12 l:12" x-gap="12" y-gap="12">
          <h3>Need Help?</h3>
          <n-list>
            <n-list-item>
              <app-icon :type="'Help'" size="14" color="#0e7a0d"/> <a href="https://help.merchr.com/en/articles/7032205-creating-your-first-product" target="_blank">Creating your first product</a>
            </n-list-item>
            <n-list-item>
              <app-icon :type="'Help'" size="14" color="#0e7a0d"/> <a href="https://help.merchr.com/en/collections/3874210-product-design-customisation" target="_blank">Product Design & Customisation</a>
            </n-list-item>
            <n-list-item>
             <app-icon :type="'Help'" size="14" color="#0e7a0d"/> <a href="https://help.merchr.com/en/articles/7032215-what-is-the-merchr-hub" target="_blank">What is the Merchr Hub?</a>
            </n-list-item>
          </n-list>
        </n-grid-item>
      </n-grid>
      <template #action>
        <a href="https://hub.merchr.com" target="_blank">
          <n-button type="primary" >Design Your Own Products</n-button>
        </a>
      </template>
    </n-card>
    <n-space vertical id="products-container">
      <n-collapse-transition :show="filter">
        <n-card embedded size="small">
          <n-form inline>
            <n-form-item label="Search">
              <n-input
                v-model:value="queryParams.keywords"
                placeholder="Product title or SKU"
                clearable
              />
            </n-form-item>
            <n-form-item style="width: 200px" label="Store">
              <n-select
                filterable
                remote
                clearable
                placeholder="Select store"
                label-field="store"
                value-field="id"
                :options="stores"
                v-model:value="queryParams.store_id"
              />
            </n-form-item>
            <n-form-item style="width: 200px" label="Status">
              <n-select
                filterable
                remote
                clearable
                placeholder="Select product status"
                :options="statuses"
                v-model:value="queryParams.status"
              />
            </n-form-item>
          </n-form>
        </n-card>
      </n-collapse-transition>
      <n-card title="Products">
        <template #header-extra>
          <n-switch v-model:value="filter" :round="false">
            <template #checked-icon>
              <app-icon type="FilterRemove"/>
            </template>
            <template #unchecked-icon>
              <app-icon type="Filter"/>
            </template>
          </n-switch>
        </template>
        <n-space vertical>
          <n-data-table
            ref="table"
            remote
            :loading="loading"
            :data="data"
            :columns="columns"
            :bordered="false"
          />
          <n-pagination v-model:page="queryParams.page" :page-count="pagination.total"/>
        </n-space>
      </n-card>
    </n-space>
  </n-space>
  <sync-logs ref="logs"/>
</template>
<script setup>
import {
  h, inject, onMounted, reactive, ref, watch,
} from 'vue';
import {
  NButton, NAvatar, NBadge, NTooltip, useDialog, useMessage,
} from 'naive-ui';
import handleErrors from '@/composables/error';
import AppIcon from '@/components/AppIcon.vue';
import { useRouter } from 'vue-router';
import SyncLogs from '@/views/products/SyncLogs.vue';

const router = useRouter();
const dialog = useDialog();
const axios = inject('axios');
const filters = inject('filters');
const message = useMessage();

const filter = ref(true);
const data = ref([]);
const loading = ref(true);
const table = ref({});
const stores = ref([]);
const statuses = ref([]);
const logs = ref(null);

const pagination = reactive({
  total: 0,
});

const queryParams = reactive({
  keywords: '',
  page: 1,
  store_id: null,
  status: null,
});

const getProducts = async () => {
  try {
    loading.value = true;
    const response = await axios.get('products', { params: queryParams });
    data.value = response.data.data;
    queryParams.page = response.data.meta.current_page;
    pagination.total = response.data.meta.last_page;
  } catch (err) {
    handleErrors(err);
  } finally {
    loading.value = false;
  }
};

const getStores = async () => {
  try {
    const response = await axios.get('stores');
    stores.value = response.data.data;
  } catch (err) {
    handleErrors(err);
  }
};

const getProductStatuses = async () => {
  try {
    const response = await axios.get('product-statuses');
    statuses.value = response.data.data;
  } catch (e) {
    handleErrors(e);
  }
};

const deleteProduct = async (id) => {
  try {
    await axios.delete(`products/${id}`);
    await getProducts();
    message.success('The product was successfully deleted. The marketplace will be updated soon.');
  } catch (e) {
    handleErrors(e);
  }
};

const getSellingPrice = (product) => (product.min_selling_price === product.max_selling_price
  ? filters.money(product.max_selling_price)
  : `${filters.money(product.min_selling_price)} - ${filters.money(product.max_selling_price)}`);

const getCost = (product) => (product.min_cost === product.max_cost
  ? filters.money(product.max_cost)
  : `${filters.money(product.min_cost)} - ${filters.money(product.max_cost)}`);

const getProfit = (product) => ((product.min_selling_price - product.min_cost)
  === (product.max_selling_price - product.max_cost)
  ? filters.money(product.max_selling_price - product.max_cost)
  : `${filters.money(product.min_selling_price - product.min_cost)} -
  ${filters.money(product.max_selling_price - product.max_cost)}`);

watch(
  () => queryParams,
  () => getProducts(),
  { deep: true },
);

onMounted(async () => {
  await getProducts();
  await getStores();
  await getProductStatuses();
});

// DataTable settings
const columns = [
  {
    title: '',
    key: 'cover',
    align: 'left',
    render(item) {
      return h(
        NBadge,
        {
          value: item.variants.length,
          color: '#a9a9a9',
          show: item.variants.length > 1,
        },
        {
          default: () => h(NAvatar, {
            'intersection-observer-options': {
              root: '#products-container',
            },
            lazy: true,
            src: item.cover,
            size: 80,
          }),
        },
      );
    },
  },
  {
    title: 'Title',
    key: 'title',
  },
  {
    title: 'SKU',
    key: 'sku',
    width: 80,
  },
  {
    title: 'Item cost',
    key: 'cost',
    width: 100,
    render(item) {
      return getCost(item);
    },
  },
  {
    title: 'Selling Price',
    key: 'sku',
    width: 100,
    render(item) {
      return getSellingPrice(item);
    },
  },
  {
    title: 'Profit',
    key: 'profit',
    width: 100,
    render(item) {
      return getProfit(item);
    },
  },
  {
    title: 'Store',
    key: 'store.store',
  },
  {
    title: 'Status',
    key: 'status_label.value',
    width: 100,
    render(product) {
      return h(NBadge, { value: product.status_label.value, type: product.status_label.type });
    },
  },
  {
    title: 'Updated At',
    key: 'updated_at',
    render(item) {
      return filters.calendar(item.created_at);
    },
  },
  {
    title: '',
    key: 'log',
    width: '50',
    render(product) {
      return h(
        NTooltip,
        { trigger: 'hover' },
        {
          trigger: () => h(
            NButton,
            {
              size: 'small',
              onClick: () => logs.value.open(product),
            },
            { default: () => h(AppIcon, { type: 'Sync' }) },
          ),
          default: () => 'Sync logs',
        },
      );
    },
  },
  {
    title: '',
    key: 'update',
    width: '50',
    render(product) {
      return h(
        NTooltip,
        { trigger: 'hover' },
        {
          trigger: () => h(
            NButton,
            {
              size: 'small',
              disabled: !![2, 5].includes(product.status),
              onClick: () => router.push(`/products/update/${product.id}`),
            },
            { default: () => h(AppIcon, { type: 'Edit' }) },
          ),
          default: () => 'Update product',
        },
      );
    },
  },
  {
    title: '',
    key: 'delete',
    width: '50',
    render(product) {
      return h(
        NTooltip,
        { trigger: 'hover' },
        {
          trigger: () => h(
            NButton,
            {
              size: 'small',
              disabled: !![2, 5].includes(product.status),
              onClick: () => dialog.error({
                title: 'Delete product?',
                content: 'Are you sure?',
                positiveText: 'Yes',
                negativeText: 'No',
                onPositiveClick: () => {
                  deleteProduct(product.id);
                },
              }),
            },
            { default: () => h(AppIcon, { type: 'TrashAlt' }) },
          ),
          default: () => 'Delete',
        },
      );
    },
  },
];
</script>
<style>
  a {
    color: rgb(0,0,0);
    text-decoration: none;
  }
  a:hover {
    color: rgb(10,162,204);
  }
  .n-list-item i {
    vertical-align: middle;
    margin-top: -3px;
  }
  .n-data-table .n-data-table-table {
    word-break: keep-all;
  }
</style>
