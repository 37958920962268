<template>
  <n-card title="Payment Settings">
    <n-select v-model:value="payment" :options="options"/>
  </n-card>
</template>
<script setup>
import { inject, onBeforeMount, ref } from 'vue';

const axios = inject('axios');

const payment = ref(null);

const options = [
  {
    label: 'Stored Card',
    value: 1,
  },
  {
    label: 'Account Balance',
    value: 2,
  },
  {
    label: 'Commission Account',
    value: 3,
  },
];

onBeforeMount(async () => {
  const response = axios.get('payment-settings');
  payment.value = response.data.data.payment_method;
});

</script>
