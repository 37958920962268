<template>
  <n-modal v-model:show="modal">
    <n-card style="width: 500px" :bordered="true" size="small" role="dialog" aria-modal="true">
      <template #header>
        Add a payment card
      </template>
      <n-space vertical size="large" style="margin: 20px 0px 20px 0px;">
        <StripeElement :element="cardElement" @change="event = $event"/>
      </n-space>
      <template #action>
        <n-space justify="end">
          <n-button type="default" @click="modal = false">Cancel</n-button>
          <n-button :loading="loading" type="success" :disabled="disabled" @click="save">
            Save
          </n-button>
        </n-space>
      </template>
    </n-card>
  </n-modal>
</template>
<script setup>
import { useStripe, StripeElement } from 'vue-use-stripe';
import { inject, ref, watch } from 'vue';
import { useMessage } from 'naive-ui';
import handleErrors from '@/composables/error';

const emit = defineEmits(['card:success']);
const { stripe, elements: [cardElement] } = useStripe({
  key: process.env.VUE_APP_STRIPE_PUBLIC_KEY || '',
  elements: [{ type: 'card', options: {} }],
});
const modal = ref(false);
const disabled = ref(true);
const event = ref(null);
const axios = inject('axios');
const loading = ref(false);
const message = useMessage();

const getPaymentIntent = async () => {
  const response = await axios.post('setup-intents');
  return response.data.data;
};

const save = async () => {
  try {
    loading.value = true;
    const setupIntent = await getPaymentIntent();
    // E.g. https://stripe.com/docs/js/setup_intents/confirm_card_setup
    const response = await stripe.value?.confirmCardSetup(setupIntent.client_secret, {
      payment_method: {
        card: cardElement.value,
      },
    });
    await axios.post('payment-cards', response.setupIntent);
    message.success('New payment card successfully added.');
    emit('card:success', true);
    modal.value = false;
  } catch (e) {
    handleErrors(e);
  } finally {
    loading.value = false;
  }
};

watch(event, () => {
  disabled.value = event.value?.complete !== true;
});

defineExpose({
  modal,
});

</script>
