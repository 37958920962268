<template>
  <div class="auth-layout">
    <n-h1>
      <img src="~@/assets/images/merchr-logo-on-light-300w.png" alt="logo" style="width: 190px" />
    </n-h1>
    <n-card size="large" style="--padding-bottom: 30px">
      <router-viewer />
    </n-card>
    <n-space justify="center" style="font-size: 10px; margin: 10px 0px 10px 0px">
      <n-a href="https://help.merchr.com/en" target="_blank">Help</n-a>
      <n-a href="https://merchr.com/privacy-policy/" target="_blank">Privacy Policy</n-a>
      <n-a href="https://merchr.com/terms-of-service/" target="_blank">Terms of Service</n-a>
    </n-space>
  </div>
</template>
<script setup>
import RouterViewer from '@/components/RouterViewer.vue';
import { useMessage } from 'naive-ui';

window.$message = useMessage();
</script>
<style scoped>
.auth-layout {
  justify-content: center;
  align-items: center;
}

.n-h1 {
  margin: 20vh auto 20px;
  text-align: center;
  letter-spacing: 5px;
  opacity: 0.8;
}
.n-card {
  margin: 0 auto;
  max-width: 380px;
  box-shadow: var(--box-shadow);
}
</style>
