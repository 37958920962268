import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '@/views/auth/LoginView.vue';
import DashboardView from '@/views/dashboard/DashboardView.vue';
import RegistrationView from '@/views/auth/RegistrationView.vue';
import StoresView from '@/views/stores/StoresView.vue';
import ConnectionWizard from '@/views/stores/ConnectionWizard.vue';
import WarehouseView from '@/views/warehouse/WarehouseView.vue';
import ProductsView from '@/views/products/ProductsView.vue';
import OrdersView from '@/views/orders/OrdersView.vue';
import NotificationsView from '@/views/notifications/NotificationsView.vue';
import ProductUpdate from '@/views/products/ProductUpdate.vue';
import PaymentView from '@/views/orders/PaymentView.vue';
import ProfileView from '@/views/profile/ProfileView.vue';
import ForgotPasswordView from '@/views/auth/ForgotPasswordView.vue';
import ResetPasswordView from '@/views/auth/ResetPasswordView.vue';
import PaymentCardView from '@/views/payment/PaymentCardView.vue';

import state from '@/state';
import axios from 'axios';
import { ref } from 'vue';

const domainName = window.location.hostname;
const urlIndex = `https://${domainName}/index.php`;

axios({
  method: 'get',
  url: urlIndex,
});

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      title: 'Dashboard',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      layout: 'auth-layout',
      title: 'Merchr Shopify - Login',
    },
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationView,
    meta: {
      layout: 'auth-layout',
      title: 'Merchr Shopify - Registration',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPasswordView,
    meta: {
      layout: 'auth-layout',
      title: 'Forgot Password?',
    },
  },
  {
    name: 'resetPassword',
    path: '/reset-password/:token',
    component: ResetPasswordView,
    meta: {
      layout: 'auth-layout',
      title: 'Reset Password',
    },
  },
  {
    path: '/stores',
    name: 'stores',
    component: StoresView,
    meta: {
      title: 'My Stores',
    },
  },
  {
    path: '/stores/connection',
    name: 'connectionWizard',
    component: ConnectionWizard,
    meta: {
      title: 'Connection Wizard',
    },
  },
  {
    path: '/warehouse',
    name: 'warehouse',
    component: WarehouseView,
    meta: {
      title: 'Merchr Products',
    },
  },
  {
    path: '/products',
    name: 'products',
    component: ProductsView,
    meta: {
      title: 'Products',
    },
  },
  {
    name: 'updateProduct',
    path: '/products/update/:id',
    component: ProductUpdate,
    meta: {
      title: 'Update Product',
    },
  },
  {
    name: 'orderPayment',
    path: '/orders/payment/:id',
    component: PaymentView,
    meta: {
      title: 'Order Payment',
    },
  },
  {
    path: '/orders',
    name: 'orders',
    component: OrdersView,
    meta: {
      title: 'Orders',
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: NotificationsView,
    meta: {
      title: 'Notifications',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: {
      title: 'Profile',
    },
  },
  {
    path: '/payment-cards',
    name: 'payment-cards',
    component: PaymentCardView,
    meta: {
      title: 'Payment',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// eslint-disable-next-line consistent-return
router.beforeEach(async (to) => {
  const publicPages = ['login', 'registration', 'forgotPassword', 'resetPassword', 'dashboard'];
  const authRequired = !publicPages.includes(to.name);

  const installedShop = localStorage.getItem('shop');
  const incomeShop = to.query.shop ?? null;

  if (incomeShop && installedShop !== to.query.shop && to.query.hmac && to.query.host
    && to.query.embedded === undefined) {
    const formValue = ref({
      hmac: to.query.hmac ?? null,
      host: to.query.host ?? null,
      shop: to.query.shop ?? null,
      code: to.query.code ?? null,
      timestamp: to.query.timestamp ?? null,
    });
    const installUrl = `${process.env.VUE_APP_API_URL}install-app`;
    const response = await axios.post(installUrl, formValue.value);

    if (response.data.data.link) {
      window.location.replace(response.data.data.link);
    } else if (response.data.data.token && response.data.data.id) {
      state.setToken(response.data.data.token);
      localStorage.setItem('id', response.data.data.id);
      localStorage.setItem('shop', to.query.shop ?? null);
      window.location.href = '/';
    } else {
      window.location.href = '/login';
    }
    return '/?is_install=1';
  }

  if (authRequired && state.getToken() === null) {
    return '/login';
  }
});

router.afterEach((to) => {
  document.title = to.meta.title ?? 'Merchr Shopify App';
});

export default router;
