import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

dayjs.extend(calendar);
dayjs().calendar(null, {
  sameDay: '[Today at] h:mm A',
  lastDay: '[Yesterday at] h:mm A',
  sameElse: 'DD/MM/YYYY',
});

const filters = {
  calendar(date) {
    return dayjs(date).calendar();
  },
  money(value, currency = 'GBP', locale = 'en-GB', minimumFractionDigits = 2) {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits,
      currencyDisplay: 'symbol',
    }).format(value);
  },

  percentage(value, locale = 'en-GB', minimumFractionDigits = 0) {
    if (value === null) {
      return '';
    }
    return Number(value / 100).toLocaleString(locale, {
      style: 'percent',
      minimumFractionDigits,
    });
  },
};

export default filters;
