<template>
  <n-space vertical>
    <n-alert v-if="!loading && withoutStore" show-icon type="warning">
      You don't have connected stores.
    </n-alert>
    <option-list v-model="filter.options" />
    <n-card title="Warehouse">
      <p>
        This is the main Merchr Warehouse, below shows all the products we currently have to
        offer, our range is growing all the time.
        <br><br>
        These products have no designs or personalisation areas but you are more than welcome to
        add these to your store if you wish. If you would like to design your own products and/or
        add personalisation areas, then head over to products and click the button to login to
        the Hub.
      </p>
    </n-card>
    <n-grid cols="24" item-responsive responsive="screen" x-gap="12" y-gap="12">
      <n-grid-item span="24 s:10 m:6" x-gap="12">
        <n-space vertical>
          <n-card>
            <n-input v-model:value="filter.keywords">
              <template #prefix>
                <app-icon type="Search" />
              </template>
            </n-input>
          </n-card>
          <filter-block
            title="Categories"
            :values="options.categories"
            v-model="filter.options.categories"
            :loading="loading"
          />
          <filter-block
            title="Collections"
            :values="options.collections"
            v-model="filter.options.collections"
            :loading="loading"
          />
          <filter-block
            title="Industries"
            :values="options.industries"
            v-model="filter.options.industries"
            :loading="loading"
          />
          <filter-block
            title="Colours"
            :values="options.colours"
            v-model="filter.options.colours"
            :loading="loading"
          />
          <filter-block
            title="Size"
            :values="options.size"
            v-model="filter.options.size"
            :loading="loading"
          />
        </n-space>
      </n-grid-item>
      <n-grid-item span="24 s:14 m:18">
        <n-empty v-if="emptySearchResult">
          Sorry we couldn't find any matcher for
          <n-text strong> {{ filter.keywords }}</n-text
          >. Please, try searching with another term.
        </n-empty>
        <skeleton-product-grid :repeat="10" v-if="loading" />
        <n-grid
          y-gap="10"
          x-gap="10"
          item-responsive
          responsive="screen"
          cols="1 m:3 l:4 xl:4 2xl:6"
        >
          <n-grid-item v-for="(product, key) in products" :key="key">
            <product-card :product="product" :stores="stores" @addProduct="addProduct" />
          </n-grid-item>
        </n-grid>
      </n-grid-item>
    </n-grid>
  </n-space>
</template>
<script setup>
import handleErrors from '@/composables/error';
import FilterBlock from '@/views/warehouse/components/FilterBlock.vue';
import ProductCard from '@/views/warehouse/components/ProductCard.vue';
import OptionList from '@/views/warehouse/components/OptionList.vue';
import SkeletonProductGrid from '@/views/warehouse/components/SkeletonProductGrid.vue';

import {
  computed, inject, onMounted, reactive, ref, watch,
} from 'vue';
import { useMessage } from 'naive-ui';

const message = useMessage();
const axios = inject('axios');
const filter = reactive({
  keywords: '',
  options: {
    industries: [],
    collections: [],
    categories: [],
    colours: [],
    size: [],
  },
});
const loading = ref(true);
const products = ref([]);
const options = ref([]);
const stores = ref([]);

const getProducts = async () => {
  try {
    const response = await axios.get('merchr-products', {
      params: { keywords: filter.keywords, ...filter.options },
    });
    products.value = response.data.data.products;
    options.value = response.data.data.options;
  } catch (err) {
    handleErrors(err);
  }
};

const getStores = async () => {
  try {
    const response = await axios.get('stores');
    stores.value = response.data.data;
  } catch (err) {
    handleErrors(err);
  }
};

const addProduct = async (storeName, storeId, productId) => {
  try {
    await axios.post('products', {
      store_id: storeId,
      product_id: productId,
    });
    message.success(
      `The product was successfully added to the ${storeName} store. You can manage it from the "Products" section.`,
    );
  } catch (err) {
    handleErrors(err);
  }
};

const withoutStore = computed({
  get: () => stores.value.length === 0,
});

const emptySearchResult = computed({
  get: () => !!(products.value.length === 0 && filter.keywords.length > 0),
});

watch(
  () => filter,
  () => getProducts(),
  { deep: true },
);

onMounted(async () => {
  loading.value = true;
  await getStores();
  await getProducts();
  loading.value = false;
});
</script>
<style scoped></style>
