<template>
  <config-provider>
    <component :is="layout" />
  </config-provider>
</template>
<script setup>
import ConfigProvider from '@/components/ConfigProvider.vue';
import { useRoute } from 'vue-router';
import { computed } from 'vue';

const route = useRoute();

const layout = computed({
  get: () => route.meta.layout ?? 'app-layout',
});
</script>
