<template>
  <n-layout-footer position="fixed">
    <n-p style="font-size: 12px;">
        &copy; {{ new Date().getFullYear() }} Merchr Limited | All rights reserved.
    </n-p>
  </n-layout-footer>
</template>

<style scoped>
.n-layout-footer {
  background: transparent;
  text-align: center;
}
.n-a {
  text-decoration: none;
}
</style>
