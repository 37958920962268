<template>
  <n-spin :show="loading">
    <n-card :title="title">
      <template #header-extra>
        <n-space>
          <n-button type="primary" ghost @click="saveProduct(true)">
            Save & Publish
          </n-button>
          <n-button @click="saveProduct">Save</n-button>
        </n-space>
      </template>
      <n-tabs type="line" animated size="large">
        <n-tab-pane name="price-settings" tab="Price settings" style="min-height: 400px">
          <n-table size="small">
            <thead>
            <tr>
              <th>&nbsp;</th>
              <th>SKU</th>
              <th>Title</th>
              <th>Item cost</th>
              <th>RRP</th>
              <th>Selling Price</th>
              <th>Profit</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(variant, key) in product.variants" :key="key">
              <td style="width: 50px">
                <n-image :src="variant.image?.medium_abs_url" :alt="variant.title"
                         :fallback-src="fallback" width="50"/>
              </td>
              <td>{{ variant.sku }}</td>
              <td>{{ variant.title }}</td>
              <td>{{ $filters.money(parseFloat(variant.cost)) }}</td>
              <td>{{ $filters.money(variant.rrp) }}</td>
              <td>
                <n-input-number :parse="parseNumber" v-model:value="variant.selling_price"/>
              </td>
              <td>
                {{ $filters.money(calculateProfit(variant)) }}
              </td>
            </tr>
            </tbody>
          </n-table>
        </n-tab-pane>
        <n-tab-pane name="description" tab="Description" style="min-height: 400px">
          <n-form ref="formRef">
            <n-form-item label="Title" path="user.name">
              <n-input v-model:value="product.title" placeholder="Product title"/>
            </n-form-item>
            <quill-editor v-model:content="product.description" content-type="html"
                          style="min-height:400px"/>
          </n-form>
        </n-tab-pane>
        <n-tab-pane name="customisation" tab="Customisation" style="min-height: 300px">
          <n-switch v-model:value="product.customisable" :unchecked-value="0" :checked-value="1">
            <template #checked>
              Disable customisation
            </template>
            <template #unchecked>
              Enable customisation
            </template>
          </n-switch>
        </n-tab-pane>
      </n-tabs>
    </n-card>
  </n-spin>
</template>

<script setup>
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { inject, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import handleErrors from '@/composables/error';
import { useMessage } from 'naive-ui';
import fallback from '@/assets/images/fallback.svg';

const route = useRoute();
const axios = inject('axios');

const product = ref({});
const formRef = ref(null);
const loading = ref(true);
const title = ref('');
const message = useMessage();

const calculateProfit = (variant) => variant.selling_price - variant.cost;

const parseNumber = (value) => Number(value);

const getProduct = async () => {
  try {
    const response = await axios.get(`products/${route.params.id}`);
    title.value = response.data.data.title;
    product.value = response.data.data;
    // strange behaviour with n-input-number
    product.value.variants.forEach((variant) => {
      // eslint-disable-next-line no-param-reassign
      variant.selling_price = Number(variant.selling_price);
    });
  } catch (e) {
    handleErrors(e);
  } finally {
    loading.value = false;
  }
};

const saveProduct = async (publish = false) => {
  try {
    loading.value = true;
    product.value.status = publish === true ? 2 : 0;
    await axios.patch(`products/${route.params.id}`, { ...product.value });
    await getProduct();
    message.success('Product successfully updated.');
  } catch (e) {
    handleErrors(e);
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  getProduct();
});
</script>

<style scoped></style>
