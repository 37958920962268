<template>
  <n-drawer v-model:show="show" :width="502">
    <n-drawer-content title="Logs" closable>
      <n-timeline>
        <n-timeline-item v-for="(log, key) in logs"
                         :type="log.status_label.type"
                         :title="log.action_label.value"
                         :content="log.response"
                         :time="$filters.calendar(log.created_at)"
                         :key="key"
        />
      </n-timeline>
    </n-drawer-content>
  </n-drawer>
</template>
<script setup>
import { ref, inject } from 'vue';

const axios = inject('axios');

const show = ref(false);
// const product = ref({});
const logs = ref([]);

// defineProps({
//   productId: {
//     type: Number,
//     required: true,
//   },
//   status: {
//     type: Boolean,
//     required: true,
//   },
// });

const open = async (syncProduct) => {
  show.value = true;
  const response = await axios.get(`products/${syncProduct.id}/sync-logs`);
  logs.value = response.data.data;
};

defineExpose({
  open,
});

</script>
