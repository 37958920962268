<template>
  <n-card title="Update profile">
    <n-form size="large" :rules="rules" :model="formValue" ref="formRef">
      <n-grid :span="24" :x-gap="24">
        <n-form-item-gi :span="12" label="First Name" path="first_name">
          <n-input v-model:value="formValue.first_name"/>
        </n-form-item-gi>
        <n-form-item-gi :span="12" label="Last Name" path="last_name">
          <n-input v-model:value="formValue.last_name"/>
        </n-form-item-gi>
        <n-form-item-gi :span="12" label="Country" path="country">
          <n-input v-model:value="formValue.country"/>
        </n-form-item-gi>
        <n-form-item-gi :span="12" label="Zip" path="zip">
          <n-input v-model:value="formValue.zip"/>
        </n-form-item-gi>
        <n-form-item-gi :span="12" label="Phone" path="phone">
          <n-input v-model:value="formValue.phone"/>
        </n-form-item-gi>
        <n-gi :span="24">
          <n-button :loading="loading" type="primary" size="large" @click="save">
            Save
          </n-button>
        </n-gi>
      </n-grid>
    </n-form>
  </n-card>
</template>
<script setup>
import { ref, inject, onBeforeMount } from 'vue';
import { useMessage } from 'naive-ui';

const message = useMessage();
const handleErrors = inject('handleErrors');
const axios = inject('axios');

const formRef = ref(null);
const formValue = ref({
  first_name: '',
  last_name: '',
  country: '',
  city: '',
  zip: '',
  phone: '',
});
const rules = {
  first_name: {},

};

const loading = ref(false);
const save = async (e) => {
  e.preventDefault();
  try {
    loading.value = true;
    await formRef.value?.validate();
    await axios.put(`users/${localStorage.getItem('id')}`, formValue.value);
    message.success('Profile successfully updated.');
  } catch (err) {
    handleErrors(err);
  } finally {
    loading.value = false;
  }
};

onBeforeMount(async () => {
  const response = await axios.get('/me');
  formValue.value = response.data.data;
});
</script>
