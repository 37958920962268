<template>
  <n-layout-sider
    :width="220"
    :native-scrollbar="false"
    :collapsed="collapsed"
    collapse-mode="width"
    show-trigger
    bordered
    @update:collapsed="collapsed = !collapsed"
  >
    <router-link to="/" #="{ navigate, href }" custom>
      <n-a class="logo" :href="href" @click="navigate">
        <img src="~@/assets/images/merchr-logo-on-light-300w.png" alt="logo" style="width: 170px" />
      </n-a>
    </router-link>
    <n-menu :options="menuOptions" :root-indent="18" />
  </n-layout-sider>
</template>

<script setup>
import { ref, h } from 'vue';
import { RouterLink } from 'vue-router';
import AppIcon from '@/components/AppIcon.vue';

const collapsed = ref(false);
// TODO: loading state

const renderIcon = (icon) => () => h(AppIcon, { type: icon });

const menuOptions = [
  {
    label: () => h(RouterLink, { to: '/' }, { default: () => 'Home' }),
    key: 'dashboard',
    icon: renderIcon('Dashboard'),
  },
  {
    label: () => h(RouterLink, { to: '/stores' }, { default: () => 'My Stores' }),
    key: 'stores',
    icon: renderIcon('BuildingShop24Regular'),
  },
  {
    label: () => h(RouterLink, { to: '/products' }, { default: () => 'My Products' }),
    key: 'products',
    icon: renderIcon('CubeSync24Regular'),
  },
  {
    label: () => h(RouterLink, { to: '/orders' }, { default: () => 'Orders' }),
    key: 'orders',
    icon: renderIcon('VehicleTruckCube24Regular'),
  },
  // {
  //   label: () => h(RouterLink, { to: '/payment-cards' }, { default: () => 'Payment Cards' }),
  //   key: 'payment-cards',
  //   icon: renderIcon('Payment24Regular'),
  // },
  {
    label: () => h(RouterLink, { to: '/warehouse' }, { default: () => 'Catalogue' }),
    key: 'warehouse',
    icon: renderIcon('BuildingWarehouse'),
  },
  {
    label: () => h(RouterLink, { to: '/notifications' }, { default: () => 'Notifications' }),
    key: 'notifications',
    icon: renderIcon('Alert24Regular'),
  },
];
</script>

<style scoped>
.logo {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  /* border-bottom: 1px solid var(--border-color); */
  background: var(--color);
  font-size: 1.8em;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  transition: padding 0.3s var(--bezier), font-size 0.3s var(--bezier);
}
.n-layout-sider--collapsed .logo {
  padding: 8px;
  font-size: 0;
}
.logo svg {
  flex: 0 0 32px;
  height: 32px;
  margin-right: 12px;
  transition: margin 0.3s var(--bezier);
}
.n-layout-sider--collapsed .logo svg {
  margin-right: 0;
}
</style>
