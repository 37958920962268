<template>
  <n-space>
    <n-tag
      v-for="option in optionList"
      closable
      @close="remove(option.key, option.value)"
      :key="option.key"
    >
      {{ option.value }}
    </n-tag>
  </n-space>
</template>
<script setup>
import { computed, toRaw } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});

const options = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});

const optionList = computed({
  get: () => {
    const list = [];
    Object.entries(props.modelValue).forEach(([key, values]) => {
      list.push(...toRaw(values).map((value) => ({ key, value })));
    });
    return list;
  },
});

const remove = (key, value) => {
  options.value[key] = options.value[key].filter((item) => item !== value);
};
</script>
