import { AxiosError } from 'axios';

const handleErrors = (error) => {
  let message = null;
  switch (error.constructor) {
    case AxiosError:
      // network error
      if (error.response.data === undefined) {
        message = error.message;
      } else {
        // response error
        message = error.response.data?.errors
          ? Object.values(error.response.data.errors).flat().join(' ')
          : error.response.data.message;
      }
      break;
    default:
      message = null;
      break;
  }
  if (message !== null) {
    window.$message.error(message, {
      closable: true,
      duration: 7000,
    });
  }
};

export default handleErrors;
