<template>
  <n-card :title="product.title" size="small" style="height: 100%" v-once>
    <template #cover>
      <n-image
        v-once
        v-if="emptyGallery"
        :src="product.cover"
        :alt="product.title"
        :fallback-src="fallback"
      />
      <n-carousel show-arrow :show-dots="false" style="height: 245px" v-else>
        <n-image
          v-once
          :src="product.cover"
          :alt="product.title"
          :key="key"
        />
        <n-image
          v-once
          v-for="(image, key) in gallery"
          :src="image.medium_abs_url"
          :alt="product.title"
          :key="key"
        />
      </n-carousel>
    </template>
    <n-popover trigger="hover" style="width: 350px; border: 1px solid #f1f1f1;">
      <template #trigger>
        {{ description }}
      </template>
      <span>{{ product.description }}</span>
    </n-popover>
    <template #action>
      <div style="justify-content: space-between; display: flex">
        <n-space>
          <n-tag :bordered="false" type="info" size="small">
            <n-text strong>{{ $filters.money((product.cost
                + product.processing_fee) * 1.2) }}</n-text>
          </n-tag>
          <n-popover trigger="hover" v-if="product.customisable">
            <template #trigger>
              <n-tag :bordered="false" type="info" size="small">
                <app-icon type="SprayPaint"/>
              </n-tag>
            </template>
            <n-space vertical>
              <n-text>Customisable product</n-text>
            </n-space>
          </n-popover>
        </n-space>
        <n-dropdown
          v-once
          v-if="multipleStores"
          key-field="id"
          label-field="store"
          show-arrow
          trigger="hover"
          :options="stores"
          @select="addProduct"
        >
          <n-button size="small" type="primary" ghost>
            <app-icon type="ShoppingCartPlus"/>
          </n-button>
        </n-dropdown>
        <n-button
          v-else
          v-once
          type="primary"
          ghost
          size="small"
          :disabled="!stores.length"
          @click="addProduct(stores[0].id)"
        >
          <app-icon type="ShoppingCartPlus"/>
        </n-button>
      </div>
    </template>
  </n-card>
</template>
<script setup>
import { computed } from 'vue';
import fallback from '@/assets/images/fallback.svg';
import AppIcon from '@/components/AppIcon.vue';

const emit = defineEmits(['addProduct']);
const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  stores: {
    type: Array,
    required: true,
  },
});

const multipleStores = computed({
  get: () => props.stores.length > 1,
});

const emptyGallery = computed({
  get: () => props.product.gallery.length <= 1,
});

const description = computed(() => (props.product.description.length >= 75 ? `${props.product.description.slice(0, 75)}..` : props.product.description));

const addProduct = (id) => {
  const store = props.stores.find((item) => item.id === id);
  emit('addProduct', store.store, store.id, props.product.id);
};

const gallery = props.product.gallery.filter(
  (image) => image.medium_abs_url !== props.product.cover,
);

</script>
<style scoped>
.carousel-img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

:deep(.creative-enter-from),
:deep(.creative-leave-to) {
  opacity: 0;
  transform: scale(0.8);
}

:deep(.creative-enter-active),
:deep(.creative-leave-active) {
  transition: all 0.3s ease;
}
</style>
