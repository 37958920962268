<template>
  <n-icon v-bind="props">
    <icon/>
  </n-icon>
</template>
<script setup>
import { NIcon } from 'naive-ui';
import {
  EyeRegular,
  EyeSlashRegular,
  BellRegular,
  Angry,
  StoreAlt,
  TruckLoading,
  Search,
  CheckCircleRegular,
  TimesCircleRegular,
  AngleLeft,
  EditRegular,
  Sync,
  Edit,
  TrashAlt,
  CreditCardRegular,
  User,
} from '@vicons/fa';
import {
  BuildingWarehouse, PlugConnected, ColorSwatch, Ruler, ShoppingCart, Help,
} from '@vicons/tabler';
import {
  CubeSync24Regular,
  BuildingShop24Regular,
  Alert24Regular,
  VehicleTruckCube24Regular,
  Payment24Regular,
} from '@vicons/fluent';
import {
  Dashboard, ShoppingCartPlus, Filter, FilterRemove, SprayPaint,
} from '@vicons/carbon';
import { computed } from 'vue';

const icons = {
  EditRegular,
  EyeRegular,
  Filter,
  FilterRemove,
  EyeSlashRegular,
  BellRegular,
  Angry,
  StoreAlt,
  BuildingWarehouse,
  TruckLoading,
  CubeSync24Regular,
  Dashboard,
  BuildingShop24Regular,
  Alert24Regular,
  VehicleTruckCube24Regular,
  Search,
  ShoppingCartPlus,
  PlugConnected,
  CheckCircleRegular,
  TimesCircleRegular,
  ColorSwatch,
  Ruler,
  AngleLeft,
  Sync,
  Edit,
  TrashAlt,
  CreditCardRegular,
  ShoppingCart,
  SprayPaint,
  Payment24Regular,
  Help,
  User,
};
const props = defineProps({
  ...NIcon.props,
  type: {
    type: String,
    default: 'fallback',
  },
});
const icon = computed({
  get: () => icons[props.type],
});
</script>
