<template>
  <n-grid x-gap="12" :cols="2">
    <n-gi>
      <n-card size="small" title="Destination">
        <n-skeleton v-if="loading" text :repeat="8"/>
        <n-descriptions label-placement="left" size="small" :column="1" v-else>
          <n-descriptions-item label="Country">
            {{ order.destination.country }}
          </n-descriptions-item>
          <n-descriptions-item label="City">
            {{ order.destination.city }}
          </n-descriptions-item>
          <n-descriptions-item label="Province" v-if="order.destination.province">
            {{ order.destination.province }}
          </n-descriptions-item>
          <n-descriptions-item label="First Name">
            {{ order.destination.first_name }}
          </n-descriptions-item>
          <n-descriptions-item label="Last Name">
            {{ order.destination.last_name }}
          </n-descriptions-item>
          <n-descriptions-item label="E-mail">
            {{ order.destination.email }}
          </n-descriptions-item>
          <n-descriptions-item label="Phone">
            {{ order.destination.phone }}
          </n-descriptions-item>
          <n-descriptions-item label="Zip">
            {{ order.destination.zip }}
          </n-descriptions-item>
          <n-descriptions-item label="Company" v-if="order.destination.company">
            {{ order.destination.company }}
          </n-descriptions-item>
          <n-descriptions-item label="Address">
            {{ order.destination.address1 }}
          </n-descriptions-item>
          <n-descriptions-item label="Address 2" v-if="order.destination.address2">
            {{ order.destination.address2 }}
          </n-descriptions-item>
        </n-descriptions>
      </n-card>
    </n-gi>
    <n-gi>
      <n-card size="small" title="Line items">
        <n-skeleton text :repeat="5" v-if="loading"/>
        <n-space vertical v-else>
          <n-thing v-for="(product, key) in order.products" :key="key">
            <template #avatar>
              <n-badge :value="product.quantity">
                <n-avatar :src="product.sync.variant.image.thumb_abs_url"/>
              </n-badge>
            </template>
            <template #header>
              <span style="font-size: 14px;">
                {{ product.sync.variant.title }}
              </span>
            </template>
            <template #header-extra>
              <span style="font-size: 14px;">
                {{ product.sync.variant.sku }}
              </span>
            </template>
            <template #description>
              <n-space vertical>
                <span>Product cost: {{ $filters.money(product.sync.variant.cost) }}</span>
                <span>{{ product.print_params }}</span>
              </n-space>
            </template>
          </n-thing>
          <n-divider dashed>
            Summary
          </n-divider>
          <n-space justify="space-between">
            <h3>Total</h3>
            <h3>{{ $filters.money(order.product_cost_sum) }}</h3>
          </n-space>
        </n-space>
        <template #action>
          <n-space justify="space-between">
            <n-popselect v-model:value="card" :options="cards">
              <n-button dashed>
                {{ selectedPaymentCard || 'Select payment card' }}
              </n-button>
              <template #empty>
                You don't have any credit cards.
              </template>
              <template #action>
                <n-button size="tiny" block @click="paymentCard.modal = true">
                  Add a new one
                </n-button>
              </template>
            </n-popselect>
            <n-button type="success" :disabled="!card" @click="pay" :loading="paymentLoading">
              Check Out
            </n-button>
          </n-space>
        </template>
      </n-card>
    </n-gi>
  </n-grid>
  <add-payment-card ref="paymentCard" @card:success="getPaymentCards"/>
</template>
<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useMessage } from 'naive-ui';
import {
  ref, inject, onBeforeMount, computed,
} from 'vue';
import handleErrors from '@/composables/error';
import '@stripe/stripe-js';
import AddPaymentCard from '@/views/orders/AddPaymentCard.vue';

const router = useRouter();
const route = useRoute();
const axios = inject('axios');
const message = useMessage();
const order = ref({});
const loading = ref(true);
const paymentLoading = ref(false);
const card = ref(null);
const paymentCard = ref(null);
const cards = ref([]);

const getOrder = async () => {
  try {
    loading.value = true;
    const response = await axios.get(`order-fulfillments/${route.params.id}`);
    order.value = response.data.data;
  } catch (e) {
    handleErrors(e);
  } finally {
    loading.value = false;
  }
};

const getPaymentCards = async () => {
  const response = await axios.get('payment-cards');
  cards.value = [];
  response.data.data.forEach((item) => {
    cards.value.push({ label: `${item.type} (* ${item.last_4})`, value: item.id });
  });
};

const pay = async () => {
  try {
    paymentLoading.value = true;
    await axios.post('payments', {
      order_fulfillment_id: order.value.id,
      card_id: card.value,
    });
    message.success('Done!');
    router.push('/orders');
  } catch (e) {
    handleErrors(e);
  } finally {
    paymentLoading.value = false;
  }
};

const selectedPaymentCard = computed({
  get: () => (card.value === null ? 'Select payment card' : cards.value.find((item) => item.value === card.value).label),
});

onBeforeMount(async () => {
  await getOrder();
  await getPaymentCards();
});

</script>
