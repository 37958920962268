<template>
  <n-card title="Do you have a Merchr account?" style="height:100%;">
    Please use your personal access token or just keep this field empty and we will create it
    automatically.
    <n-form ref="formRef" :model="formValue" :rules="rules">
      <n-form-item path="personal_access_token">
        <n-input size="large"
          v-model:value="formValue.personal_access_token"
          placeholder="Merchr Personal Access Token"
        >
          <template #prefix>
            <app-icon type="PlugConnected" />
          </template>
        </n-input>
      </n-form-item>
    </n-form>
    <template #action>
      <n-button :loading="loading" @click="handle">Next</n-button>
    </template>
  </n-card>
</template>
<script setup>
import { ref, inject, reactive } from 'vue';

const emit = defineEmits(['step:status']);
const axios = inject('axios');
const formRef = ref(null);
const loading = ref(false);
const formValue = reactive({
  personal_access_token: '',
});
const rules = {
  personal_access_token: [
    {
      validator() {
        return new Promise((resolve, reject) => {
          axios.post('merchr-users', formValue).then(() => {
            resolve();
          }).catch((error) => {
            reject(error.response.data.message);
          });
        });
      },
    },
  ],
};

const handle = async () => {
  try {
    loading.value = true;
    await formRef.value?.validate();
    emit('step:status', true);
  } catch (err) {
    emit('step:status', false);
  } finally {
    loading.value = false;
  }
};
</script>
